Platform.Deferred.execute(setListViewScript);
var selectedProducts = [];
var selectedProductsWithOptions = [];
var productSelected = {};
function LoadFilters() {
    var root = $('body').data('root');

    $('.sub-cat-holder-match-height').matchHeight();
    //$('.product-match-height').matchHeight();
    $('.product-info-match-height').matchHeight();

    var wrapper = $('#pf-product-list-page');
    var filter = wrapper.data("filter");
    var colourFilter = wrapper.data("filter-colour");
    var brandFilter = wrapper.data("brand-filter");
    var catFilter = wrapper.data("cat-filter");
    var catId = wrapper.data("cat-id");
    if (catId == "undefined") { catId = "" };
    var brandId = wrapper.data("brand-id");
    if (brandId == "undefined") { brandId = "" };
    var searchString = encodeURIComponent(wrapper.data("search-string"));
    var filterEnabled = wrapper.data("filter-enabled");
    var filterDisplay = wrapper.data("filter-on");
    var listType = wrapper.data("list-type");
    var minPriceFilter = wrapper.data("min-price-filter");
    var maxPriceFilter = wrapper.data("max-price-filter");

    if (filterEnabled == 'True' && filterDisplay == 'True') {
        $('#filter').html("<div class='pf-loading-spinner'></div>").load(root + "Widgets/ProductFilterWidget?listType=" + listType + "&catId=" + catId + "&brandId=" + brandId +
            "&searchString=" + searchString + "&filter=" + filter + "&brandFilter=" + brandFilter +
            "&minPriceFilter=" + minPriceFilter + "&maxPriceFilter=" + maxPriceFilter + "&colourFilter=" + colourFilter + "&catFilter=" + catFilter);
        // Todo may need to re-design the way this is used, as loading the colour filter for CoachHouse in this way caused delays many . 
        $('#filtersOnly').load(root + "Widgets/GetSpecFilters?catId=" + catId + "&brandId=" + brandId +
            "&searchString=" + searchString + "&filter=" + filter + "&brandFilter=" + brandFilter + "&colourFilter=" + colourFilter);
        $('#filtersColourOnly').load(root + "Widgets/GetColourFilters?catId=" + catId + "&brandId=" + brandId +
            "&searchString=" + searchString + "&filter=" + filter + "&brandFilter=" + brandFilter + "&colourFilter=" + colourFilter);

    }
}
function setListViewScript() {
    var root = $('body').data('root');
    $('.sub-cat-holder-match-height').matchHeight();
    //$('.product-match-height').matchHeight();
    $('.product-info-match-height').matchHeight();

    var wrapper = $('#pf-product-list-page');
    var useLoadMoreOnScroll = wrapper.data('use-load-more-on-scroll');
    LoadFilters();

    var url = wrapper.data('url');

    // add handler for filter options
    $(document).on('click', '.ecom-filter-val', function () {
        FilterProducts(url);
    });
    $(document).on('click', '.ecom-filter-colour-val', function () {
        FilterProducts(url);
    });

    $(document).on('click', '.pf-paging-btn', function () {
        var pagenum = $(this).data('pagenum');
        FilterProducts(url, pagenum);
    });

    $(document).on('click', '.pf-load-more-btn', function () {
        var limit = $(this).data('limit');
        FilterProducts(url, null, limit, true);
    })

    if (useLoadMoreOnScroll == 'True') {
        var productsLoading = false;
        $(window).scroll(function () {
            if ($('.pf-load-more-container').length) {
                var containerOffset = $('#list-products-wrapper').offset().top,
                    containerHeight = $('#list-products-wrapper').outerHeight(),
                    windowHeight = $(window).height(),
                    windowTop = $(this).scrollTop();
                if ((windowTop > (containerOffset + containerHeight - windowHeight)) && !productsLoading) {
                    productsLoading = true;
                    var limit = $('.pf-load-more-container').data('limit');
                    $('.pf-load-more-loading').show();
                    FilterProducts(url, null, limit, true, function () {
                        $('.pf-load-more-loading').hide();
                        productsLoading = false;
                        $('#list-products-wrapper').waitForImages(function () {
                            $('.sub-cat-holder-match-height').matchHeight();
                            //$('.product-match-height').matchHeight();
                            $('.product-info-match-height').matchHeight();
                        });
                    });
                }
            }
        });
    }

    $(document).on('click', '.clear-filters-button', function () {
        $(".ecom-filter-val").prop("checked", false);
        $(".ecom-filter-colour-val").prop("checked", false);
        FilterProducts(url);
    });
    
    $(document).on('click', '.clear-filters-button', function () {
        ClearFilters(url);
    });
    
    $(document).on('change', '.list-view-select', function () {
        var val = $(this).val();
        FilterProducts(url);
    });
    
    $(document).on('change', '.ecom-filter-val-select', function () {
        FilterProducts(url);
    });
    // Mobile filter button
    //$(document).on('click', '.filter-heading.mobile', function ()
    //{                 
    //    if ($('.m-filter-wrapper').hasClass("mobile-filter-visible"))
    //    {
    //        $(".filter-heading-icon").html("+");
    
    //    }
    //    else {
    //        $(".filter-heading-icon").html("-");
    
    //    }        
    //    $('.m-filter-wrapper').toggleClass('mobile-filter-visible');  
    //});
    
    // stock notify handler
    var stockNotifyProdId;
    var stockNotifyIsVariant = "False";
    var stockNotifyLoggedIn = "False";
    var stockNotifyEmail = "";
    var stockModal = $('[data-remodal-id=stock-notify-modal]').remodal();
    
    $(document).on('click', '.pf-stock-notify-me-btn', function () {
        stockNotifyProdId = $(this).data("prod-id");
        stockNotifyIsVariant = $(this).data("is-variant");
        stockNotifyLoggedIn = $(this).data("user-logged-in");
        if (stockNotifyLoggedIn == "False") {
            $('#stock-notify-content').show();
            $('#stock-notify-rsp').hide();
            stockModal.open();
        }
        else {
            RegisterStockNotify();
        }
    });
    
    $('#register-stock-notify-form').submit(function (e) {
        e.preventDefault();
        stockNotifyEmail = $('#stock-notify-email').val();
        RegisterStockNotify();
    });
    
    function RegisterStockNotify() {

    $.ajax({
        url: root + "UserAccount/RegisterStockNotify",
        type: 'POST',
        dataType: 'json',
        data: JSON.stringify({
            prodId: stockNotifyProdId,
            isVariant: stockNotifyIsVariant,
            email: stockNotifyEmail
        }),
        contentType: 'application/json; charset=utf-8',
        success: function (data) {
            if (data.result == "ok") {
                if (stockNotifyLoggedIn) {
                    $('#stock-notify-content').hide();
                    $('#stock-notify-rsp').show();
                    stockModal.open();
                }
                else {
                    $('#stock-notify-content').fadeOut(function () {
                        $('#stock-notify-rsp').fadeIn();
                    });
                }
            }
            else {
                alert('Sorry there was a problem processing your request.');
            }
        },
        error: function () {
            alert('Sorry there was a problem processing your request.');
        }
    });
}
    
    // adding to cart
    $(document).on('click', '.product-list-add-to-cart-btn', function () {
    var fbPixelEnabled = $('#product-view-container').data("fb-pixel");
    var btn = $(this);
    var id = btn.data("id");
    var wrapper = btn.closest('.list-view-add-cart-wrapper');
    var qty = wrapper.find('.qty-tbx').val();

    $.ajax({
        url: root + "Cart/AddToCart",
        type: 'POST',
        dataType: 'json',
        data: JSON.stringify({ productId: id, quantity: qty }),
        contentType: 'application/json; charset=utf-8',
        success: function (data) {
            //AddedToCart(data);
            $(document).trigger('itemAdded.product.platform', data);
            //SetCartQty();
            if (fbPixelEnabled == "True") {
                fbq_addToBag();
            }
        }
    });

    return false;
});
    
    $(document).on('click', '.pf-add-all-to-cart-btn', function () {
    var $productsTarget = $($(this).data('products-target'));
    var products = [];
    $productsTarget.find('.pf-product-list-item').each(function () {
        var $this = $(this);
        if ($this.data('has-variants') !== 'True' && $this.data('has-options') !== 'True' && $this.data('has-options-adv') !== 'True') {
            products.push({
                ProductId: $(this).data('id'),
                Quantity: $(this).find('.qty-tbx').val()
            });
        }
    });

    $.ajax({
        url: root + 'Cart/AddProductsToCart',
        type: 'POST',
        dataType: 'json',
        data: JSON.stringify(products),
        contentType: 'application/json; charset=utf-8',
        success: function (data) {
            $(document).trigger('itemAdded.product.platform', data);
            if (fbPixelEnabled == "True") {
                fbq_addToBag();
            }
        }
    });

    return false;
});
    
    $(document).on('click', '.list-view-add-cart-wrapper .pf-qty-btn-minus', function () {
    var wrapper = $(this).closest('.qtywrapper');
    var qtyTbx = wrapper.find('.qty-tbx');
    var qty = Number(qtyTbx.val());
    var uom = qtyTbx.data('uom');
    var min = qtyTbx.data('min');

    var minQty = min;
    if (minQty % uom !== 0)
        minQty = minQty - (minQty % uom) + uom;

    if (qty > uom)
        qty -= uom;

    if (minQty > 1 && qty < minQty) {
        qty = minQty;
        Lobibox.notify('warning', {
            msg: 'This product has a minimum order quantity of ' + minQty,
            icon: 'fas fa-info-circle',
            title: 'Minimum Order Quantity'
        });
    }
    qtyTbx.val(qty);
});
    
    $(document).on('click', '.list-view-add-cart-wrapper .pf-qty-btn-plus', function () {
    var wrapper = $(this).closest('.qtywrapper');
    var qtyTbx = wrapper.find('.qty-tbx');
    var qty = Number(qtyTbx.val());
    var uom = qtyTbx.data('uom');
    qty += uom;
    qtyTbx.val(qty);
});
    
    // group products
    $(document).on({
    mouseenter: function () {
        var swapIn = $(this).data("image-url");
        var mainImgContainer = $(this).closest('.pf-product-list-item').find('.pf-list-item-img');
        var mainImage = mainImgContainer.prop('src');
        $(this).data("orig-img-url", mainImage);
        mainImgContainer.prop('src', swapIn);
        $(this).addClass('pf-list-group-image-selected');
    },
    mouseleave: function () {
        var swapIn = $(this).data("orig-img-url");
        var mainImgContainer = $(this).closest('.pf-product-list-item').find('.pf-list-item-img');
        mainImgContainer.prop('src', swapIn);
        $(this).removeClass('pf-list-group-image-selected');
    },
    click: function () {
        // Handle click...
    }
}, ".pf-list-view-group-prods-wrapper .pf-list-view-group-prod");
    
    //Multi select product list
    $(document).on('click', '.pf-select-products-btn', function () {
    if ($(this).hasClass('pf-multi-selected')) {
        $('.pf-select-all-products-btn').css('display', 'none');
        $('.pf-multi-select-wrapper').css('display', 'none');
        $('.pf-product-list-bottom ').css('display', 'none');
        $(this).removeClass('pf-multi-selected');
        $(this).html('Select Products');
        $("#pf-product-listing .pf-multi-select-product").each(function (index) {
            $(this).removeClass('selected');
        });
        $("#pf-product-listing .pf-multi-select-item").each(function (index) {
            $(this).prop('checked', false);
        });
        $('#pf-product-listing .pf-product-list-item').each(function (index) {
            var prodId = $(this).data('id');
            var element = $('.pf-multi-select-items-wrapper').find(".pf-multi-selected-product[data-product-id='" + prodId + "']");
            element.remove();
            selectedProducts = jQuery.grep(selectedProducts, function (value) {
                return value != prodId;
            });
            selectedProductsWithOptions = jQuery.grep(selectedProductsWithOptions, function (value) {
                return value != prodId;
            });
        });
    }
    else {
        $('.pf-select-all-products-btn').css('display', 'inline-block');
        $('.pf-multi-select-wrapper').css('display', 'block');
        $('.pf-product-list-bottom ').css('display', 'block');
        $(this).addClass('pf-multi-selected');
        $(this).html('Deselect All');
    }
    //$('.product-match-height').matchHeight();
});
    $(document).on('click', '.pf-multi-select-icon', function () {
    var input = $(this).siblings();
    var productWrapper = $(this).closest('.pf-product-list-item');
    var selectedDiv = productWrapper.find('.pf-multi-select-product');
    if (input.is(':checked')) {
        input.prop('checked', false);
        selectedDiv.removeClass('selected');
        //Remove product from summary
        var prodId = productWrapper.data('id');
        var element = $('.pf-multi-select-items-wrapper').find(".pf-multi-selected-product[data-product-id='" + prodId + "']");
        if (element.data('has-variants') == 'True' || element.data('has-options') == 'True' || element.data('has-options-adv') == 'True') {
            selectedProductsWithOptions = jQuery.grep(selectedProductsWithOptions, function (value) {
                return value != prodId;
            });
        }
        else {
            selectedProducts = jQuery.grep(selectedProducts, function (value) {
                return value != prodId;
            });
        }
        element.remove();
    }
    else {
        if (jQuery.inArray(productWrapper.data('id'), selectedProducts) == -1) {
            input.prop('checked', true);
            selectedDiv.addClass('selected');
            //Adding product to summary
            productSelected.prodId = productWrapper.data('id');
            productSelected.prodName = productWrapper.find('.pf-product-name').text();
            productSelected.prodImg = productWrapper.find('.pf-list-item-img').attr('src')
                || $('.pf-product-main-image-wrapper').find('.img:first').attr('src');
            productSelected.hasVariants = productWrapper.data('has-variants');
            productSelected.hasOptions = productWrapper.data('has-options');
            productSelected.hasOptionsAdv = productWrapper.data('has-options-adv');
            productSelected.UoM = 1;
            if (productWrapper.find('.pf-add-cart-wrapper').find('.pf-qty-wrapper').length) {
                productSelected.UoM = productWrapper.find('.pf-qty-select').data('uom');
            }
            renderMultiSelectProduct();
            if (productSelected.hasVariants == 'True' || productSelected.hasOptions == 'True' || productSelected.hasOptionsAdv == 'True') {
                selectedProductsWithOptions.push(productSelected.prodId);
            }
            else {
                selectedProducts.push(productSelected.prodId);
            }
        }
    }
});
    $(document).on('click', '.pf-select-all-products-btn', function () {
    $('#pf-product-listing .pf-product-list-item').each(function (index) {
        var prodId = $(this).data('id');
        var element = $('.pf-multi-select-items-wrapper').find(".pf-multi-selected-product[data-product-id='" + prodId + "']");
        element.remove();
        selectedProducts = jQuery.grep(selectedProducts, function (value) {
            return value != prodId;
        });
        selectedProductsWithOptions = jQuery.grep(selectedProductsWithOptions, function (value) {
            return value != prodId;
        });
    });
    $('#pf-product-listing .pf-multi-select-product').each(function (index) {
        $(this).addClass('selected');
    });
    $('#pf-product-listing .pf-multi-select-item').each(function (index) {
        $(this).prop('checked', true);
    });
    $('#pf-product-listing .pf-product-list-item').each(function (index) {
        productSelected.prodId = $(this).data('id');
        productSelected.prodName = $(this).find('.pf-product-name').text();
        productSelected.prodImg = $(this).find('.pf-list-item-img').attr('src')
            || $('.pf-product-main-image-wrapper').find('.img:first').attr('src');
        productSelected.hasVariants = $(this).data('has-variants');
        productSelected.hasOptions = $(this).data('has-options');
        productSelected.hasOptionsAdv = $(this).data('has-options-adv');
        productSelected.UoM = 1;
        if ($(this).find('.pf-add-cart-wrapper').find('.pf-qty-wrapper').length) {
            productSelected.UoM = $(this).find('.pf-qty-select').data('uom');
        }
        renderMultiSelectProduct();
        if (productSelected.hasVariants == 'True' || productSelected.hasOptions == 'True' || productSelected.hasOptionsAdv == 'True') {
            selectedProductsWithOptions.push(productSelected.prodId);
        }
        else {
            selectedProducts.push(productSelected.prodId);
        }
    });
});
    $('body').on('click', 'a.pf-multi-select-remove-item', function () {
    var productWrapper = $(this).closest('.pf-multi-selected-product');
    if (productWrapper.data('has-variants') == 'True' || productWrapper.data('has-options') == 'True' || productWrapper.data('has-options-adv') == 'True') {
        selectedProductsWithOptions = jQuery.grep(selectedProductsWithOptions, function (value) {
            return value != productWrapper.data('product-id');
        });
    }
    else {
        selectedProducts = jQuery.grep(selectedProducts, function (value) {
            return value != productWrapper.data('product-id');
        });
    }
    //Delete selected status from main product list
    var input = $('#list-products-wrapper').find(".pf-multi-select-item[data-prod-id='" + productWrapper.data('product-id') + "']");
    input.closest('.pf-product-list-item').find('.pf-multi-select-product').removeClass('selected');
    input.prop('checked', false);
    //Removing from summary
    productWrapper.remove();
});
    function renderMultiSelectProduct() {
    var newItem = $('#MultiSeletedItemTemplate').clone();
    newItem.attr('id', '');
    newItem.attr('data-product-id', productSelected.prodId);
    newItem.attr('data-has-variants', productSelected.hasVariants);
    newItem.attr('data-has-options', productSelected.hasOptions);
    newItem.attr('data-has-options-adv', productSelected.hasOptionsAdv);
    newItem.attr('data-uom', productSelected.UoM);
    newItem.find('.pf-product-name').html(productSelected.prodName);
    newItem.find('img').attr('src', productSelected.prodImg);
    newItem.appendTo('.pf-multi-select-items-wrapper');
}
    $(document).on('click', '.pf-multi-select-action-buttons-wrapper .pf-qty-btn-minus', function () {
    var wrapper = $(this).closest('.qtywrapper');
    var qtyTbx = wrapper.find('.qty-tbx');
    var qty = Number(qtyTbx.val());
    var uom = 1;
    var min = qtyTbx.data('min');
    var minQty = min;
    if (minQty % uom !== 0)
        minQty = minQty - (minQty % uom) + uom;

    if (qty > uom)
        qty -= uom;

    if (minQty > 1 && qty < minQty) {
        qty = minQty;
        Lobibox.notify('warning', {
            msg: 'This product has a minimum order quantity of ' + minQty,
            icon: 'fas fa-info-circle',
            title: 'Minimum Order Quantity'
        });
    }
    qtyTbx.val(qty);
});
    $(document).on('click', '.pf-multi-select-action-buttons-wrapper .pf-qty-btn-plus', function () {
    var wrapper = $(this).closest('.qtywrapper');
    var qtyTbx = wrapper.find('.qty-tbx');
    var qty = Number(qtyTbx.val());
    var uom = 1;
    qty += uom;
    qtyTbx.val(qty);
});
    $(document).on('click', '.pf-multi-select-action-buttons-wrapper .pf-multi-select-all-to-cart', function () {
    var qty = $('.pf-multi-select-action-buttons-wrapper').find('.qty-tbx').val();
    var fbPixelEnabled = $('#product-view-container').data("fb-pixel");
    //Handle normal products
    console.log(selectedProducts);
    console.log(selectedProductsWithOptions);
    $.each(selectedProducts, function (key, value) {
        var product = $('.pf-multi-select-items-wrapper').find("[data-product-id='" + value + "']");
        //var UoM = product.data('uom');
        //var qtyTotal = qty * UoM;
        $.ajax({
            url: root + "Cart/MultiSelectAddToCart",
            async: false,
            type: 'POST',
            dataType: 'json',
            data: JSON.stringify({ prodId: value, qty: qty }),
            contentType: 'application/json; charset=utf-8',
            success: function (data) {
                // standard cart handler
                $(document).trigger('itemAdded.product.platform', data);
                if (fbPixelEnabled == "True") {
                    fbq_addToBag();
                }
            }
        });
        console.log(value);
    });
    $(document).on('click', '#platformModal .pf-add-to-cart-btn', function () {
        var fbPixelEnabled = $('#product-view-container').data("fb-pixel");
        var btn = $(this);
        var row = btn.closest('.pf-add-cart-row');
        var id = btn.data("id");
        var opts = btn.data("options");
        var variantId = $(btn).data("variant-id");
        var qty = row.find('.pf-qty-select').val();
        var optionsAdv = $(this).data('options-adv');

        $.ajax({
            url: root + "Cart/AddToCart",
            type: 'POST',
            dataType: 'json',
            data: ({
                productId: id,
                variantId: variantId === 0 ? null : variantId,
                quantity: qty,
                options: opts,
                optionsAdv: optionsAdv
            }),
            //contentType: 'application/json; charset=utf-8',
            success: function (data) {
                //AddedToCart(data);
                $(document).trigger('itemAdded.product.platform', data);
                $('.pf-quick-add-to-cart-alert').hide();
                var pModal = $('[data-remodal-id=platformModal]').remodal();
                pModal.close();
                $('.pf-quick-order-quick-entry-code').val('').focus();
                //SetCartQty();
                if (fbPixelEnabled == "True") {
                    fbq_addToBag();
                }

                $("#cart-product-" + data.id).show();
            }
        });

        return false;
    });

    //Handle products with options
    var handleNextItem = function (selectedIndex, selectedProductsWithOptions) {
        if (selectedIndex >= selectedProductsWithOptions.length)
            return;

        var productId = selectedProductsWithOptions[selectedIndex];
        var product = $('.pf-multi-select-items-wrapper').find("[data-product-id='" + productId + "']");
        //var UoM = product.data('uom');
        //var qtyTotal = qty * UoM;
        $.ajax({
            url: root + "Cart/MultiSelectAddToCart",
            async: false,
            type: 'POST',
            dataType: 'json',
            data: JSON.stringify({ prodId: productId, qty: qty }),
            contentType: 'application/json; charset=utf-8',
            success: function (data) {
                if (data.result == "showpopup") {
                    //show popup for product
                    $.ajax({
                        url: root + "Products/SelectProductOptions",
                        async: false,
                        type: 'POST',
                        data: { id: productId },
                        success: function (rsp) {
                            $(document).off('closed', '#platformModal').on('closed', '#platformModal', function (e) {
                                handleNextItem(selectedIndex + 1, selectedProductsWithOptions);
                            });
                            SetPlatformModalContent("Select Options for - " + product.find('.pf-product-name').text(), rsp);
                            OpenPlatformModal("650px");
                        }
                    });
                }
                else if (data.result == "ok-out-of-stock" || data.result == "ok-stock-limited") {
                    handleNextItem(selectedIndex + 1, selectedProductsWithOptions);
                }
                else {
                    // standard cart handler
                    $(document).trigger('itemAdded.product.platform', data);
                    if (fbPixelEnabled == "True") {
                        fbq_addToBag();
                    }
                }
            }
        });
    }
    handleNextItem(0, selectedProductsWithOptions);
    //remove items from summary and items selected
    $("#pf-product-listing .pf-multi-select-product").each(function (index) {
        $(this).removeClass('selected');
    });
    $("#pf-product-listing .pf-multi-select-item").each(function (index) {
        $(this).prop('checked', false);
    });
    $('#pf-product-listing .pf-product-list-item').each(function (index) {
        var prodId = $(this).data('id');
        var element = $('.pf-multi-select-items-wrapper').find(".pf-multi-selected-product[data-product-id='" + prodId + "']");
        if (element.data('has-variants') == 'True' || element.data('has-options') == 'True' || element.data('has-options-adv') == 'True') {
            selectedProductsWithOptions = jQuery.grep(selectedProductsWithOptions, function (value) {
                return value != prodId;
            });
        }
        else {
            selectedProducts = jQuery.grep(selectedProducts, function (value) {
                return value != prodId;
            });
        }
        element.remove();
    });
    $('.pf-product-list-bottom ').css('display', 'none');
    $('.pf-multi-select-wrapper').css('display', 'none');
    $('.pf-select-all-products-btn').css('display', 'none');
    $('.pf-select-products-btn').removeClass('pf-multi-selected');
    $('.pf-select-products-btn').html('Select Products');
    return false;
});
}

//function AddedToCart(data) {
//    if (data.result == "ok") {
//        GetAndOpenTopCart();
//        $("html, body").animate({ scrollTop: 0 }, "slow"); // scroll to top
//    }
//    if (data.result == "ok-stock-limited") {
//        alert("Sorry there are only " + data.qty + " of these items available in stock.");
//        GetAndOpenTopCart();
//        $("html, body").animate({ scrollTop: 0 }, "slow"); // scroll to top
//    }
//    if (data.result == "ok-out-of-stock") {
//        alert("Sorry this item is no longer in stock.");
//        GetTopCart();
//    }
//}

function FilterProducts(url, pagenum, limit, isLoadMore, callback) {
    // get the list of selected options and build querystring
    var urlParameters = GetFilterFromUrl();
    var attributes = urlParameters.attributes;
    var colours = $('.ecom-filter-group[data-filter-type=col]').length == 0 ? urlParameters.colours : [];
    var brands = $('.ecom-filter-group[data-filter-type=brand]').length == 0 ? urlParameters.brands : [];
    var categories = $('.ecom-filter-group[data-filter-type=category]').length == 0 ? urlParameters.categories : [];

    $('.ecom-filter-group').each(function () {
        var id = $(this).data('filter-attr');
        var filterType = $(this).data('filter-type');
        var attrItem = [];
        $(this).closest('.pf-filter-dropdown').find('.ecom-filter-val').each(function () {
            if ($(this).is(':checked')) {
                if (filterType == "val") {
                    attrItem.push($(this).data('filter-attr-val'));
                }
                else if (filterType == "brand") {
                    if (!brands.includes($(this).val())) {
                        brands.push($(this).val());
                    }
                }
                else if (filterType == "category") {
                    if (!categories.includes($(this).val())) {
                        categories.push($(this).val());
                    }
                }
            }
        });
        if (filterType == "val") {
            attributes[id] = attrItem.join('-');
        }
    });
    $('.ecom-colour-group').each(function () {
        var filterType = $(this).data('filter-type');
        $(this).closest('.pf-filter-dropdown').find('.ecom-filter-colour-val').each(function () {
            if ($(this).is(':checked')) {
                if (filterType == "col") {
                    if (!colours.includes($(this).data('filter-colour-val'))) {
                        colours.push($(this).data('filter-colour-val'));
                    }
                }
            }
        });
    });
    var wrapper = $('#pf-product-list-page');
    wrapper.data('filter-colour', colours.join('-'));
    // attributes
    for (var attributeId in attributes) {
        if (!attributes[attributeId]) {
            delete attributes[attributeId];
        }
    }
    var attributesString = $.map(attributes, function (attrValues, attrId) {
        return attrId + '|' + attrValues;
    }).join(',');

    //// gets new url to call

    var newUrl = UpdateQueryString("pf", attributesString, url);
    wrapper.data('filter', attributesString);
    if (pagenum) {
        newUrl = UpdateQueryString("page", pagenum, newUrl);
    }
    else if (urlParameters.pagenum) {
        newUrl = UpdateQueryString("page", urlParameters.pagenum, newUrl);
    }

    // query limit dropdown
    // if populated update limit value based on parameter.
    if (limit) {
        newUrl = UpdateQueryString("limit", limit, newUrl);
    }
    else {
        var productLimit = $('#product-limit').val();
        newUrl = UpdateQueryString("limit", productLimit, newUrl);
    }

    //// brands
    qs = brands.join('-');
    newUrl = UpdateQueryString("br", qs, newUrl);
    wrapper.data('brand-filter', qs);

    //Categories
    qs = categories.join('-');
    newUrl = UpdateQueryString("ca", qs, newUrl);
    wrapper.data('cat-filter', qs);
    //Colour
    cs = colours.join('-');
    newUrl = UpdateQueryString("cf", cs, newUrl);

    // price ranges
    var minPrice = $('#min-price-filter').val();
    if (typeof minPrice != 'undefined' && minPrice != -1) {
        newUrl = UpdateQueryString("minprice", minPrice, newUrl);
    }
    var maxPrice = $('#max-price-filter').val();
    if (typeof maxPrice != 'undefined' && maxPrice != -1) {
        newUrl = UpdateQueryString("maxprice", maxPrice, newUrl);
    }

    // sorting
    var sort = $('#product-sort').val();
    if (typeof sort != 'undefined') {
        newUrl = UpdateQueryString("listby", sort, newUrl);
    }
    else if (urlParameters.listby) {
        newUrl = UpdateQueryString("listby", urlParameters.listby, newUrl);
    }

    if (newUrl == newUrl.replace("Category", "ListProducts")) {
        //if not a expected async call, just redirect.

        if (isLoadMore) {
            var productIndex = $('.flex-col.border-spaced').length;
            newUrl += "#p" + productIndex;
        }
        window.location = newUrl;
    } else {
        //redirect to async call and update the list wrapper html on response
        if (isLoadMore) {
            var productIndex = $('.flex-col.border-spaced').length;
            newUrl += "#p" + productIndex;
        }
        newUrl = newUrl.replace("Category", "ListProducts");

        $.ajax({
            url: newUrl,
            type: 'GET',
            contentType: 'application/json; charset=utf-8',
            success: function (data) {
                // update URL for re-navigating to the page if required.  
                history.pushState({ id: 'products' }, 'pagetitle', newUrl.replace("ListProducts", "Category"))
                $('#list-products-wrapper').html(data);
                $('#list-products-wrapper').waitForImages(function () {
                    $('.sub-cat-holder-match-height').matchHeight();
                    //$('.product-match-height').matchHeight();
                    $('.product-info-match-height').matchHeight();
                });
                LoadFilters();
                wishListBtnClickHandler();
                if (!isLoadMore) {
                    $('html, body').animate({
                        scrollTop: $("#pf-product-list-page").offset().top
                    }, 700);
                }
                else {
                    $('html, body').animate({
                        scrollTop: $("#p" + productIndex).offset().top
                    }, 700);
                }
                if (callback)
                    callback(true);
                setTimeout(function () {
                    $('.productsOnCart').each(function () {
                        var productId = $(this).val();
                        var quantity = $(this).attr('quantity');
                        $('#cart-product-' + productId).css('display', 'block');
                        $('#quantityFor_' + productId).text(quantity);
                        //$('.product-match-height').matchHeight();
                    });
                }, 500);
            },
            error: function () {
                if (callback)
                    callback(false);
                alert('Sorry there was a problem processing your request.');
            }
        });
    }
}

function UpdateQueryString(key, value, url) {
    if (!url) url = window.location.href;
    var re = new RegExp("([?&])" + key + "=.*?(&|#|$)(.*)", "gi"),
        hash;

    if (re.test(url)) {
        if (typeof value !== 'undefined' && value !== null)
            return url.replace(re, '$1' + key + "=" + value + '$2$3');
        else {
            hash = url.split('#');
            url = hash[0].replace(re, '$1$3').replace(/(&|\?)$/, '');
            if (typeof hash[1] !== 'undefined' && hash[1] !== null)
                url += '#' + hash[1];
            return url;
        }
    }
    else {
        if (typeof value !== 'undefined' && value !== null) {
            var separator = url.indexOf('?') !== -1 ? '&' : '?';
            hash = url.split('#');
            url = hash[0] + separator + key + '=' + value;
            if (typeof hash[1] !== 'undefined' && hash[1] !== null)
                url += '#' + hash[1];
            return url;
        }
        else
            return url;
    }
}

function ClearFilters(url) {
    // call url
    var newUrl = UpdateQueryString("pf", null, url);
    newUrl = UpdateQueryString("br", null, newUrl);
    newUrl = UpdateQueryString("cf", null, newUrl);
    newUrl = UpdateQueryString("ca", null, newUrl);
    window.location = newUrl;
}

function InitFilterHeader() {
    $('.pf-filter-header').off("click"); // can cause duplicate events to be bound on ajax load. try to remove onclick event before adding a new one. 
    $('.pf-filter-header').on("click", function () {
        var filter = $(this).closest('.pf-filter-header').next('.pf-filter-items');
        var trigger = $(this).find('.pf-filter-main-dropdown-btn');
        if (filter.is(':visible')) {
            filter.slideUp();
            trigger.addClass('closed');
            trigger.removeClass('open');
        }
        else {
            filter.slideDown();
            trigger.removeClass('closed');
            trigger.addClass('open');
        }
    });
}

function InitCats() {
    $('.filter-cats-sub').hide();
    var selectedLink = $('.cat-selected');

    // open cat parents for selected cat
    var parents = selectedLink.parents('nav');
    parents.show();
    $('.pf-filter-dropdown-btn.opened').parent().siblings('.filter-cats-sub').show();
    selectedLink.parents('.filter-cats').siblings('.pf-filter-item').find('.pf-filter-dropdown-btn').removeClass('closed');
    //parents.find('.filter-dropdown-icon').removeClass('closed');
    //parents.find('.filter-dropdown-icon').addClass('open');

    // open filter specs for selected
    var filterSelectedParent = $('.ecom-filter-val.filter-selected').closest('.pf-filter-dropdown-btn');
    filterSelectedParent.find('.pf-filter-dropdown-btn').removeClass('closed');
    filterSelectedParent.find('.filter-dropdown-list').show();

    $('.pf-filter-dropdown-btn').click(function () {
        var parent = $(this).closest('.pf-filter-item');
        if (parent.next('nav').is(':visible')) {
            parent.next('nav').slideUp();
            parent.find('.pf-filter-dropdown-btn').addClass('closed');
        }
        else {
            parent.next('nav').slideDown();
            parent.find('.pf-filter-dropdown-btn').removeClass('closed');
        }
        //$(this).next('nav').slideToggle().toggleClass('filter-closed');
    });

    if ($('.ecom-filter-val.filter-selected')[0]) {
        ShowClearFilterBtn();
    }
}

function InitSpecs() {
    // open filter specs for selected
    var filterSelectedParent = $('.ecom-filter-val.filter-selected').closest('.filter-dropdown');
    filterSelectedParent.find('.pf-spec-filter-dropdown-btn').removeClass('closed');
    filterSelectedParent.find('.filter-dropdown-list').show();
    $(document).on('click', '.pf-spec-filter-dropdown-btn', function (e) {
        e.preventDefault();
        if (!$(this).data('isClicked')) {
            var link = $(this);
            var parent = $(this).closest('.pf-filter-item');
            if (parent.next('nav').is(':visible')) {
                parent.next('nav').slideUp();
                parent.find('.pf-spec-filter-dropdown-btn').addClass('closed');
            }
            else {
                parent.next('nav').slideDown();
                parent.find('.pf-spec-filter-dropdown-btn').removeClass('closed');
            }
            link.data('isClicked', true);
            setTimeout(function () {
                link.removeData('isClicked')
            }, 3000);
        }
        //$(this).next('nav').slideToggle().toggleClass('filter-closed');
    });

    if ($('.ecom-filter-val.filter-selected')[0]) {
        ShowClearFilterBtn();
    }
}
function InitColours() {

    // open filter colour if selected
    var filterSelectedParent = $('.ecom-filter-colour-val.filter-selected').closest('.filter-dropdown');
    filterSelectedParent.find('.pf-colour-filter-dropdown-btn').removeClass('closed');
    filterSelectedParent.find('.filter-dropdown-list').show();

    $(document).on('click', '.pf-colour-filter-dropdown-btn', function () {
        var parent = $(this).closest('.pf-colour-item');
        if (parent.next('nav').is(':visible')) {
            parent.next('nav').slideUp();
            parent.find('.pf-colour-filter-dropdown-btn').addClass('closed');
        }
        else {
            parent.next('nav').slideDown();
            parent.find('.pf-colour-filter-dropdown-btn').removeClass('closed');
        }
        //$(this).next('nav').slideToggle().toggleClass('filter-closed');
    });

    if ($('.ecom-filter-colour-val.filter-selected')[0]) {
        ShowClearFilterBtn();
    }
}

function ShowClearFilterBtn() {
    $('#pf-clear-filters-btn').css('display', 'inline-block');
}
function GetFilterFromUrl() {
    var bestSellers = false;
    var bestSellersUserGroup = '';
    var attributes = {};
    var colours = [];
    var brands = [];
    var categories = [];
    var listby = null;
    var pagenum = null;
    var limit = null;
    var existingUrl = $('#pf-product-list-page').data('url');
    var baseUrl = $('body').data('root');
    existingUrl = existingUrl.replace(baseUrl, "");
    var productListUrlRegex = /^(?:(all)|(products\/search)|(category)\/([0-9]+)\/[^ \/?]+|(bestsellers)(?:\/([0-9]+))?)/i
    var existingUrlMatch = productListUrlRegex.exec(existingUrl);
    if (existingUrlMatch) {
        if (existingUrlMatch[3]) {
            // Category link
            categories.push(existingUrlMatch[4]);
        } else if (existingUrlMatch[5]) {
            // Best sellers link
            bestSellers = true;
            bestSellersUserGroup = existingUrlMatch[6];
        }
        var pfRegex = /^pf=((?:\d+\|\d+(?:-\d+)*,?)*)$/i; // Use existing code on match[1] instead of existingUrlMatch[6]
        var brRegex = /^br=((?:\d(?:-\d)*)*)$/i; // Use existing code on match[1] instead of existingUrlMatch[7]
        var caRegex = /^ca=((?:\d(?:-\d)*)*)$/i; // Use existing code on match[1] instead of existingUrlMatch[8]
        var cfRegex = /^cf=((?:\d(?:-\d)*)*)$/i; // Use existing code on match[1] instead of existingUrlMatch[9]
        var listbyRegex = /^listby=([a-z]*)$/i; // Use new code, match[1] will be the sort option text
        var pageRegex = /^page=(\d*)$/i; // Use new code, match[1] will be the page number
        var limitRegex = /^limit=(\d*)$/i; // Use new code, match[1] will be the limit number
        var queryString = location.search.substring(1); // Remove the ?
        $.each(queryString.split('&'), function (_, queryStringPart) {
            var pfRegexMatch = pfRegex.exec(queryStringPart);
            if (pfRegexMatch && pfRegexMatch[1]) {
                // Specs query string
                $.each(pfRegexMatch[1].split(','), function (_, spec) {
                    var specParts = spec.split('|');
                    if (specParts.length !== 2)
                        return true;
                    attributes[specParts[0]] = specParts[1];
                });
                return true; // Continue on to next query string part after finding match
            }
            var brRegexMatch = brRegex.exec(queryStringPart);
            if (brRegexMatch && brRegexMatch[1]) {
                // Brands query string
                $.each(brRegexMatch[1].split('-'), function (_, brandId) {
                    brands.push(brandId);
                });
                return true;
            }
            var caRegexMatch = caRegex.exec(queryStringPart);
            if (cfRegexMatch && caRegexMatch[1]) {
                // Cats query string
                $.each(caRegexMatch[1].split('-'), function (_, catId) {
                    categories.push(catId);
                });
                return true;
            }
            var cfRegexMatch = cfRegex.exec(queryStringPart);
            if (cfRegexMatch && cfRegexMatch[1]) {
                // Colours query string
                $.each(cfRegexMatch[1].split('-'), function (_, colourId) {
                    colours.push(colourId);
                });
                return true;
            }
            var listbyRegexMatch = listbyRegex.exec(queryStringPart);
            if (listbyRegexMatch && listbyRegexMatch[1]) {
                listby = listbyRegexMatch[1];
                return true;
            }
            var pageRegexMatch = pageRegex.exec(queryStringPart);
            if (pageRegexMatch && pageRegexMatch[1]) {
                pagenum = pageRegexMatch[1];
                return true;
            }
            var limitRegexMatch = limitRegex.exec(queryStringPart);
            if (limitRegexMatch && limitRegexMatch[1]) {
                limit = limitRegexMatch[1];
                return true;
            }
        });
    }
    return {
        attributes: attributes,
        brands: brands,
        categories: categories,
        colours: colours,
        bestSellers: bestSellers,
        bestSellersUserGroup: bestSellersUserGroup,
        listby: listby,
        pagenum: pagenum,
        limit: limit
    };
}
